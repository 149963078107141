module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-K77P6HV","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-source-prismic/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"kursori-blog","accessToken":"MC5ZTHpOYWhJQUFDSUE3U3dv.MA5jJhpQ77-9H--_vWvvv71s77-977-977-977-9bGvvv73vv70k77-9XAs6RO-_ve-_vTo-Kh8","schemas":{"blog-post":{"uid":{"type":"UID","config":{"label":"UID"}},"title":{"type":"Text","config":{"label":"Title"}},"lede":{"type":"Text","config":{"label":"Lede"}},"cover_image":{"type":"Image","config":{"constraint":{},"thumbnails":[],"label":"Cover Image"}},"body":{"type":"StructuredText","config":{"multi":"paragraph,preformatted,heading1,heading2,heading3,heading4,heading5,heading6,strong,em,hyperlink,image,embed,list-item,o-list-item,rtl","label":"Body"}}}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.svg","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"92670c741c4b1e629a4001b6b36f60bf"},
    }]
